.professionals-team {
    text-align: center;
    margin-bottom: 40px;
    margin-left: 80px;
    margin-right: 80px;
  }

  .professionals-team > h1 {
    font-size: 40px;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: capitalize;
    margin: auto auto 20px auto;
  }

  .professionals-team > span {
    font-size: 18px;
    color: #666;
  }
  
  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .team-member {
    width: 200px; /* Adjust the width as needed */
    margin: 20px;
  }
  
  .member-image {
    /* width: 150px; */
    /* height: 150px; */
    margin: 0 auto;
    /* border-radius: 50%; */
    overflow: hidden;
  }
  
  .member-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .team-member h3 {
    margin-top: 10px;
    font-size: 18px;
  }
  
  .team-member p {
    margin-top: 5px;
    font-size: 14px;
    color: #888;
  }
  
  .social-links {
    margin-top: 10px;
  }
  
  .social-links a {
    display: inline-block;
    margin-right: 5px;
    color: #333;
    font-size: 20px;
  }
  
  .social-links a:hover {
    color: #007bff; /* Change color on hover */
  }
  

  @media screen and (max-width: 768px) {
    .professionals-team{
      margin: 0 40px 40px 40px;
    }
    
  }
.footer {
    background-image: url("../../img/hero-two-bg.png") ;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.footer-container {
    margin: 0px 80px;
    padding-top: 40px;
}

.footer-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0;
}

.footer-logo > .img  > img{
    width: 200px;
}

.social {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social a {
    text-decoration: none;
    color: black;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    gap: 5px;
    flex-wrap: wrap;
}

.social a:hover {
    color: #55e6a5;
}

.medium-conatiner-footer {
    display: flex;
    justify-content: space-between;
}

.medium-conatiner-footer-first {
    /* border: 1px solid red; */
}

.medium-conatiner-footer-first > span {
    font-size: 65px;
    font-weight: 600;
    margin: auto;
}

.medium-conatiner-footer-first > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 40px 0;
    gap: 20px;
}

.medium-conatiner-footer-first > div > a {
    background-color: #fff;
    padding: 15px 40px;
    border-radius: 50px;
    border: 1px solid #666;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: #000;
    transition: all 0.5s ease;
    font-weight: 600;
    /* font-size: 20px; */
}

.medium-conatiner-footer-first > div > a:hover {
    background-color: #55e6a5;
    border: none;
    font-size: 25px;
}

.medium-conatiner-footer-second {
    /* border: 1px solid red; */
    width: 50%;
    display: flex;
    justify-content: center;
}

.quick-Links {

}

.quick-Links > h3 {
    font-size: 25px;
    font-weight: 600;
}

.quick-Links > ul {
    
}

.quick-Links > ul > li {
    font-weight: 600;
    list-style: none;
    padding: 10px 0;
}

.quick-Links > ul > li > a {
    text-decoration: none;
    color: #000;
}

.quick-Links > ul > li > a:hover {
    color: #55e6a5;
    text-decoration: underline;
}

.copy {
    display: flex;
    justify-content: center;
    align-items: center;
}

.copy > p {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}


@media screen and (max-width: 768px) {
    .footer-container {
        margin: 0 40px;
    }

    .medium-conatiner-footer {
        flex-direction: column;
    }
    .medium-conatiner-footer-second {
        display: block;
    }

    .footer-logo {
        flex-direction: column;
        justify-content: center;
        gap: 20px;
    }
}

@media screen and (max-width: 616px) {
    .footer-container {
        margin: 0 20px;
    }

    .medium-conatiner-footer-first > div {
        flex-direction: column;
    }

    .copy > p {
        font-size: 14px;
    }
}
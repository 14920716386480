/* FAQ.css */

.faq {
    background-color: #f9f9f9;
    padding: 40px 0;
  }
  
  .faq-container {
    margin: 0 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .faq-img img {
  
  }
  
  .faq-content {
    margin-left: 20px;
    flex: 1;
  }
  
  .faq-header {
    margin-bottom: 20px;
  }
  
  .faq-header span {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #333;
  }
  
  .faq-header span svg {
    margin-right: 10px;
  }
  
  .faq-header h2 {
    font-size: 36px;
    color: #222;
  }
  
  .faq-questions {
    margin-top: 20px;
  }
  
  .faq-question {
    margin-bottom: 30px;
  }
  
  .faq-question summary {
    font-size: 20px;
    cursor: pointer;
    color: #555;
    transition: color 0.3s ease;
  }
  
  .faq-question p {
    margin-top: 10px;
    font-size: 16px;
    color: #666;
    line-height: 1.6;
  }
  
  .faq-question details {
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .faq-container {
      flex-direction: column;
      align-items: center;
    }
    
    .faq-content {
      margin-left: 0;
      margin-top: 30px;
      width: 100%;
    }
  
    .faq-img img {
      margin-bottom: 20px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .faq-container {
        margin: 0 20px;
    }
    .faq-img img {
        width: 200px;
    }
  }
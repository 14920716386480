/* Services.css */

.services {
    padding-top: 110px;
    padding-bottom: 100px;
    background-image: url("../../img/hero-two-bg.png");

  }
  
  .services-container {
    margin: 0 80px;
  }
  
  .services-header {
    text-align: center;
    margin-bottom: 60px;
  }
  
  .popular-services {
    display: block;
    font-size: 24px;
    margin-bottom: 15px;
  }

  .services-header > h2 {
    font-size: 40px;
    font-weight: 600;
  }
  
  .service-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .service-card {
    width: calc(33.33% - 20px); /* Show three cards per row by default */
    margin-bottom: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  @media screen and (max-width: 786px) { /* For tablets and below */
    .service-card {
      width: calc(50% - 20px); /* Show two cards per row on tablets */
    }
    .services-container{
      margin: 0 40px;
    }
  }
  
  @media screen and (max-width: 480px) { /* For mobile devices */
    .service-card {
      width: calc(100% - 20px); /* Show one card per row on mobile devices */
    }
    .service-cards {
      justify-content: center;
    }
  }
  
  .service-card:hover {
    background-color: #55e6a5;
  }
  
  .service-card img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #f1f1f1;
  }
  
  .service-details {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 20px ;
    font-weight: 600;
  }
  
  .service-title {
    display: block;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .service-button {
    background-color: #333;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .service-button > a {
    color: #fff;
  }
  
  .service-button:hover {
    background-color: #000;
    
  }
  
.choose {

}

.choose-container {
    margin: 40px 80px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.choose-content {

}

.choose-header {
    /* border: 1px solid #000; */

}

.choose-header > span {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    color: #666;
}

.choose-header > h2 {
    font-size: 40px;
    font-weight: 600;
    text-transform: capitalize;
}

.choose-cards {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-top: 40px;
}


.choose-box {
   margin-bottom: 50px;
   margin-right: 20px;
   /* border: 1px solid #000; */
}

.choose-box > div:first-child {
 display: flex;
 align-items: center;
 gap: 10px;
 padding-bottom: 25px;
 margin-bottom: 25px;
 border-bottom: 1px solid #666;
}

.choose-box > div > span {
    font-size: 22px;
    font-weight: 500;
    gap: 10px;
    margin-bottom: 20px;
    color: #666;
    text-align: center;
}


.choose-image {
    /* width: 50%; */
    overflow: hidden;
}

.choose-image > img {
overflow: hidden;
}


@media screen and (max-width:1150px) {
    .choose-container {
        flex-direction: column;
    }
}

@media screen and (max-width:576px) {
    .choose-container {
        margin: 40px 20px;
    }

    .choose-box > div > span {
        font-size: 16px;
    }
    .choose-box > div:first-child {
        padding-bottom: 5px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width:400px) {
    .choose-box > div > span {
        font-size: 12px;
    }
}
/* Styles for the hero section */
.main-container {
  /* padding: 0 120px; */
}


.hero-section {
    background-image: url('../../img/hero-two-bg.png');
    background-size: cover;
    background-position: center;
    padding: 80px 0;
    text-align: center;
  }
  
  
  /* Styles for the container */
  .container {
    max-width: 960px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  /* Styles for the inner div containing hero content */
  .hero-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  /* Styles for the hero content */
  .hero-content {
    /* max-width: 600px; */
    margin-top: 10px;
  }
  
  /* Styles for the hero title */
  .hero-title {
    font-size: 120px;
    color: #333;
    margin-bottom: 20px;
    width: 100%;
  }
  
  /* Styles for the hero description */
  .hero-description {
    font-size: 18px;
    color: #666;
    margin-bottom: 30px;
  }
  
  /* Styles for the primary button */
  .btn {
    display: inline-block;
    padding: 10px 20px;
    background-color: #55e6a5;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    color: #333;
    font-weight: 600;
  }
  
  .btn:hover {
    background-color: #55e6a5;
  }
  

  .hero-second {
    display: flex;
    justify-content: center;
    margin: 20px 80px; 
  }
  
  .hero-second-first {
    background-color: #55e6a5;
    display: flex;
    align-items: center; /* Align items vertically */
    padding: 0 20px;
    border: 1px solid #000;
    border-radius: 10px;
  }
  
  .image-container {
    margin-right: 20px; 
    align-self: end;
  }
  
  .banner-image {
    height: auto;
  }
  
  .text-container {
    display: flex;
    flex-direction: column;
  }
  
  .project-count {
    font-size: 100px;
    font-weight: 600;
    color: #000;
  }
  
  .project-description {
    font-size: 40px;
    color: #000;
    margin-bottom: 10px; /* Adjust spacing between lines */
  }
  
  .project-summary {
    font-size: 20px;
    color: #000;
  }
  
  .skills {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin: 0 80px;

  }
  
  .skills h2 {
    font-size: 40px;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: capitalize;
    margin: auto auto 20px auto;
  }
  
  .skills ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .skills li {
    font-size: 18px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 15px 25px;
    border-radius: 40px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid #fff;
    font-weight: 600;
  }

  .skills li:hover {
    border: 4px solid #55e6a5;
  }
  
  .skills li svg {
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
  

  
  /* Styles for desktop */

.hero-title {
  font-size: 120px;
}

.hero-description {
  font-size: 18px;
}

.project-count {
  font-size: 100px;
}

.project-description {
  font-size: 40px;
}

.project-summary {
  font-size: 20px;
}

.skills li {
  font-size: 18px;
}

.skills li svg {
  width: 30px;
  height: 30px;
}



.about-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-left: 80px;
  margin-right: 80px;
}

.about-first {
  flex: 0 0 auto;
  width: 58.3333%;
}

.section-title {
  margin-top: -7px;
  position: relative;
}

.section-title .sub-title {
  font-size: 18px;
    font-weight: 500;
    align-items: center;
    display: inline-flex;
    text-transform: capitalize;
}

.section-title h2 {
  text-transform: capitalize;
  font-size: 48px;
}

.text-left-border {
  padding-left: 40px;
  max-width: 615px;
  color: #333;
  border-left: 4px solid #55e6a5;
}

.about-counter {
  padding-left: 40px;
}

.about-couter-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  margin: -10px -15px -15px -15px;
}

.about-couter-container > div {
  margin-right:40px ;
}

.about-couter-container > div > h1 {
  font-size: 40px;
  font-weight: 400;
  text-transform: capitalize;
}

.about-second {
  flex: 0 0 auto;
  width: 41.6666%;
} 

.image-border-shape {
  position: relative;
  max-width: max-content;
}

.image-border-shape > img {
  max-width: 100%;
  display:inline-block;
}

.bottom-border {
  width: 330px ;
  height: 330px;
  border: 65px solid;
  border-radius: 50%;
}

.image-border-shape .bottom-border {
  position: absolute;
  right: -15px;
  bottom: 50px;
  z-index: -1;
  transform: rotate(20deg);
}

.recent-work {
  /* border: 1px solid; */
}

.recent-work-conatiner {
  margin: 0 80px;
}

.react-work-headline {
  text-align: center;
  margin: 30px 0;
}

.react-work-headline > span {
 font-size: 18px;
 font-weight: 500;
 display: inline-flex;
 color: #333;
}

.react-work-headline > h2 {
  font-size: 40px;
  text-transform: capitalize;
  font-weight: 600;
}


.project-work-container {
  display: grid;
  grid-template-columns: repeat(2,1fr); /* Two columns in a row */
  gap: 40px; /* Adjust the gap between project work boxes */
  margin: 80px 0;
}

.project-work-box {
  position: relative;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  border-bottom: 1px solid #666;
  padding-bottom: 35px;
  margin-bottom: 55px;
}

.project-image {
  /* display: none;  */
  position: absolute;
    right: -50px;
    top: -40%;
    max-width: 50%;
    -webkit-transform: scale(0) rotate(-30deg);
    -ms-transform: scale(0) rotate(-30deg);
    transform: scale(0) rotate(-30deg);
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.project-work-box:hover .project-image {
  visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1) rotate(13.43deg);
    -ms-transform: scale(1) rotate(13.43deg);
    transform: scale(1) rotate(13.43deg);
}

.project-work-box:hover img {

}

.blog-section {
 
}

.blog-section-container {
  text-align: center;
  margin: 40px 80px;
}

.blog-section-container > span {
  text-align: center;
  color: #666;
  margin-bottom: 20px;
}

.blog-section-container > h2 {
  font-size: 40px;
  text-transform: capitalize;
  font-weight: 600;
  text-align: center;
  margin: 20px auto;
}

.blog-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blog-box {
  width: calc(33.33% - 20px);
    margin-bottom: 30px;
    overflow: hidden;
}

.blog-box > div > h4 {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  text-align: justify;
  margin: 20px 0;
}

.blog-box > div > h4 > a {
  color: #333;
  text-decoration: none;
}

.blog-box > div > h4 > a:hover {
  color: #55e6a5;
  text-decoration: underline;
}


/* Media queries for tablet */
@media only screen and (max-width: 992px) {
  .hero-title {
    font-size: 80px;
  }

  .project-count {
    font-size: 60px;
  }

  .project-description {
    font-size: 32px;
  }

  .project-summary {
    font-size: 18px;
  }

  .skills h2 {
    font-size: 31px;
  }

  .skills li {
    font-size: 16px;
    margin-bottom: 15px;
    padding: 10px 15px;
  }

  .skills li svg {
    width: 25px;
    height: 25px;
    margin-right: 8px;
  }

  .about-section {
    flex-direction: column;
    gap:  30px;
  }

  .about-first {
    width: 100%;
  }

  .about-second{
    width: 100%;
  }

  .project-work-container {
    grid-template-columns: repeat(1,1fr);
  }

  .project-image {
    right: 13px;
    top: -54px;
  }
}

@media screen and (max-width: 768px) {
  .hero-second {
    margin: 20px 40px;
  }
  
  .skills{
    margin: 0 40px;
  }

  .about-section {
    margin-left: 40px;
    margin-right: 40px;
  }

  .recent-work-conatiner {
    margin: 0 40px;
  }

  .blog-section-container {
    margin: 40px 40px;
  }

  .blog-content {
    justify-content: center;
    gap: 20px;
  }

  .blog-box {
    width: calc(50% - 20px);
  }
  
}

/* Media queries for mobile */
@media only screen and (max-width: 576px) {
  
  .hero-title {
    font-size: 60px;
  }

  .hero-description {
    font-size: 16px;
  }

  .project-count {
    font-size: 40px;
  }

  .project-description {
    font-size: 24px;
  }

  .project-summary {
    font-size: 16px;
  }

  .skills h2 {
    font-size: 23px;
  }

  .skills li {
    font-size: 14px;
    margin-bottom: 10px;
    padding: 8px 12px;
  }

  .skills li svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  .project-image  > img {
    width: 180px;
  }

  .blog-box {
    width: calc(100% - 20px);
  }
}



/* Media queries for mobile */
@media only screen and (max-width: 493px) {

  .hero-title {
    font-size: 51px;
  }

  .hero-second-first {
    flex-direction: column;
    padding: 20px;
  }

  .image-container {
    align-self: normal;
    margin: 0;
  }

  .hero-description {
    font-size: 16px;
  }

  .project-count {
    font-size: 40px;
  }

  .project-description {
    font-size: 24px;
  }

  .project-summary {
    font-size: 16px;
  }

  .skills h2 {
    font-size: 18px;
    width: 100%;
  }

  .skills li {
    font-size: 14px;
    margin-bottom: 10px;
    padding: 8px 12px;
  }

  .skills li svg {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  .about-counter .about-couter-container {
    flex-direction: column;
  }

  .image-border-shape .bottom-border {
    right: 30px;
  }
  .bottom-border {
    width: 200px;
    height: 200px;
    border: 50px solid;
    border-radius: 50%;
  }
}

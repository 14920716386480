.plan {
  /* Add your custom styles for the .plan class here */
}

.plan-container {
  margin: 0 80px;
}

.plan-header {
  margin-bottom: 60px;
}

.plan-header > span {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #666;
}

.plan-header > h2 {
  text-transform: capitalize;
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 20px;
}

.plan-header > p {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #666;
}


.plan-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.plan-box {
  width: calc(25% - 20px); /* 4 boxes per row on large screens */
  height: 200px; /* Set the height for the square box */
  background-color: #ffffff;
  transition: background-color 0.3s;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}


.plan-box:hover {
  background-color: #55e6a5; 
}

.plan-box > div {
 
}

.plan-box  > div:first-child {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid black;
}

.plan-box  > div:first-child > span {
  font-size: 22px;
  font-weight: 500;
  color: #666;
}

.plan-box > div > div:first-child > h3 {
 font-size: 22px;
 font-weight: 600;
}

@media screen and (max-width: 992px) {
  .plan-box {
    width: calc(50% - 20px); 
  }
}

@media screen and (max-width: 576px) {
  .plan-box {
    width: calc(100% - 20px); 
  }
}

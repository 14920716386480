nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Add transition for smoother effect */
}

/* Add box-shadow to the navbar */
nav.scrolled {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; /* Adjust values as needed */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.logo img {
  width: 150px;
}

.section {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
}

.section ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.section ul li {
  margin: 0 15px;
}

.section ul li a {
  text-decoration: none;
  color: black;
  position: relative;
}

.section ul li a:hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #55e6a5;
  bottom: -2px;
  left: 0;
}

.section ul li button {
  background-color: #55e6a5;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
}

.hamburger {
  font-size: 24px;
  cursor: pointer;
  display: none;
}

@media only screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .section {
    display: none;
    position: absolute;
    top: 85px;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 1;
    border-top: 1px solid #666;
  }

  .section ul {
    flex-direction: column;
  }

  .section.open {
    display: flex;
    flex-direction: column;
  }

  .section ul li {
    margin: 15px 0;
  }
}


.testimonial {
    margin-bottom: 20px;
    /* border: 1px solid red; */
  }
  
  .testimonial-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 80px;
    gap: 20px;
  }
  
  .left-content {
    margin-right: 20px;
  }
  
  
 .testimonial-content {
  /* border: 1px solid; */
  width: 50%;
  display: flex;
  margin: auto;
  flex-direction: column;
  gap: 20px;
 }

 .testimonial-message {
  font-size: 30px;
  font-weight: 400;
 }

 @media screen and (max-width: 768px) {

  .testimonial-container {
    flex-direction: column;
    margin:  0 40px;
  }

  .testimonial-content {
    width: 100%;
    padding: 20px;
  }

  .testimonial-message {
    font-size: 16px;
  }
 }
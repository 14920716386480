/* About.css */

.about-container {
    padding: 20px;
  }
  
  .about-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-content h1 {
    color: #55e6a5;
  }
  
  .about-content h2 {
    color: #55e6a5;
    margin-top: 40px;
  }
  
  .about-content p {
    color: #333;
    line-height: 1.6;
  }
  
  .about-content ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #333;
  }
  
  .about-content li {
    margin-bottom: 10px;
  }
  
  /* Additional styles as needed */
  
.services-details-container {

}

.services-details-content {

}

.services-details-header {
    background-image: url("../img/hero-two-bg.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 120px 50px;
}

.services-details-header > span {
    font-size: 85px;
    font-weight: 600;
    line-height: 1.2;
}

.services-details-main-content {
    display: flex;
    flex-direction: row;
    margin: 80px 80px;
    gap: 40px;
}

.services-details-main-content-image {
    width: 50%;
    overflow: hidden;
}

.services-details-main-content-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

 .services-details-main-content-box {
    width: 50%;
}

.services-details-main-content > div:last-child > div {
    margin-top: 30px;
}

.services-details-main-content > div:last-child > div > span {
    color: #666;
    font-size: 20px;
    display: flex;
    align-items: center;
}


.services-details-main-content > div:last-child > div > h1 {
    font-size: 40px;
    font-weight: 600;
}

.services-details-main-content > div:last-child  > div:last-child {
    padding: 0 60px;
}

.services-details-main-content > div:last-child  > div:last-child > p {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    margin:  20px 0;
}

.services-details-main-content > div:last-child  > div:last-child > ul > li {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    margin:  20px 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;
}

.services-details-main-content > div:last-child  > div:last-child > ul > li > span {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.5;
    display: flex;
    justify-content: center;
    color: #55e6a5;
}


@media screen and (max-width: 768px){
    .services-details-main-content {
        flex-direction: column;
        margin: 80px 40px;
    }

    .services-details-main-content-image {
        width: 100%;
    }

    .services-details-main-content-box {
        width: 100%;
    }

    .services-details-header {
        padding: 100px 30px;
    }
    .services-details-header > span  {
        font-size: 60px;
    }
}

@media screen and (max-width: 576px){
    .services-details-header {
        padding:80px 20px;
    }

    .services-details-header > span {
        font-size: 35px;
    }
    .services-details-header > img {
        max-width: 10%;
    }

    .services-details-main-content {
      margin: 80px 20px ;
    }

}
.quota {
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  .quota-container {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .quota-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .quota-form {
   
  }

  .quota-form-conatiner {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
    margin:20px 0;
  }


  .form-group:last-child {
    grid-column: 1 / -1; /* Span across all columns */
  }
 
  
  .form-group {
    display: grid;
    gap: 5px;
  }
  
  label {
    font-weight: bold;
    color: #555;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea,
  input[type="file"] {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #fff;
    transition: border-color 0.3s;
  }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="tel"]:focus,
  textarea:focus,
  input[type="file"]:focus {
    border-color: #007bff;
  }
  
  button[type="submit"] {
    padding: 12px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
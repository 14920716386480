/* Contact.css */

.contact-us {
    background-color: #f9f9f9;
    padding: 40px 20px;
  }
  
  .contact-container {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .contact-details,
  .contact-form {
    flex: 1;
    max-width: 400px;
    margin-right: 20px;
  }
  
  .contact-details h2,
  .contact-form h2 {
    font-size: 32px;
    color: #222;
    margin-bottom: 20px;
  }
  
  .contact-details ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .contact-details ul li {
    font-size: 18px;
    color: #666;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .contact-form form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form label {
    font-size: 16px;
    color: #222;
    margin-bottom: 8px;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .contact-form textarea {
    resize: vertical;
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: #55e6a5;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #47cf8d;
  }
  
  .contact-map {
    flex: 1;
    margin-top: 40px;
  }
  
  .contact-map iframe {
    width: 100%;
    height: 300px;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  